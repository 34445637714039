/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/gallery/1.jpg",
    alt: ""
  }), "\n", React.createElement(_components.img, {
    src: "/gallery/2.jpg",
    alt: ""
  }), "\n", React.createElement(_components.img, {
    src: "/gallery/3.jpg",
    alt: ""
  }), "\n", React.createElement(_components.img, {
    src: "/gallery/6.jpg",
    alt: ""
  }), "\n", React.createElement(_components.img, {
    src: "/gallery/9.jpg",
    alt: ""
  }), "\n", React.createElement(_components.img, {
    src: "/gallery/10.jpg",
    alt: ""
  }), "\n", React.createElement(_components.img, {
    src: "/gallery/13.jpg",
    alt: ""
  }), "\n", React.createElement(_components.img, {
    src: "/gallery/14.jpg",
    alt: ""
  }), "\n", React.createElement(_components.img, {
    src: "/gallery/20.jpg",
    alt: ""
  }), "\n", React.createElement(_components.img, {
    src: "/gallery/22.jpg",
    alt: ""
  }), "\n", React.createElement(_components.img, {
    src: "/gallery/25.jpg",
    alt: ""
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
